<template>
  <v-container>
    <v-card class="px-7 py-9" :elevation="isModal ? '0' : '2'">
      <h1 class="mb-5 pl-3">{{ $t('components.processingPersonalData.title') }}</h1>
      <ul>
        <li class="my-2 pb-2">
          {{ $t('components.processingPersonalData.textOne') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.processingPersonalData.textTwo') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.processingPersonalData.textThree') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.processingPersonalData.textFour') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.processingPersonalData.textFive') }}
        </li>
        <li class="my-2 pb-2">{{ $t('components.processingPersonalData.textSix') }}</li>
      </ul>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ProcessingPersonalData',
  props: ['modal'],
  computed: {
    isModal() {
      return this.modal || '';
    },
  },
};
</script>
